

class UserNotLogged extends Error {
    constructor(){
        super('User not logged')
    }
}

class AccessDenied extends Error {
    constructor(){
        super('Access Denied')
    }
}


class ServerError extends Error {
    constructor(){
        super('Server Error')
    }
}

export {UserNotLogged, AccessDenied, ServerError}