import { baseApiUrl } from "../config"

async function fetchPost(endpoint:string, data?:any, jwt?:string){
  console.log('POST:',baseApiUrl + endpoint)
  const response = await fetch(baseApiUrl + endpoint,{
    method: "POST",
    //mode: "cors",
    referrerPolicy: "no-referrer",
    
    headers: {
      "accept":"application/json",
      "content-type": "application/json",
      ...(jwt ? {"Authorization": `Bearer ${jwt}`}:null)
    },

    ...(!!data ? {body:JSON.stringify(data)} : null)
  })
  
  return response.json()
}

async function fetchPut(endpoint:string, data?:any, jwt?:string){
  console.log('PUT:',baseApiUrl + endpoint, JSON.stringify(data))
  const response = await fetch(baseApiUrl + endpoint,{
    method: "PUT",
    //mode: "cors",
    referrerPolicy: "no-referrer",
    
    headers: {
      "accept":"application/json",
      "content-type": "application/json",
      ...(!!jwt ? {"Authorization": `Bearer ${jwt}`}:null)
    },

    ...(!!data ? {body:JSON.stringify(data)} : null)
  })
  
  try {
    return await response.json()
  } catch(err) {
    return {}
  }
}

async function fetchGet(endpoint:string, data?:any, jwt?:string){

    let url = baseApiUrl + endpoint
    if(!!data){
        url += '?' + (new URLSearchParams(data))
    }

    console.log('GET:',url)

    const response = await fetch(url,{
      method: "GET",
      //mode: "cors",
      referrerPolicy: "no-referrer",
      
      headers: {
        "accept":"application/json",
        "content-type": "application/json",
        ...(jwt ? {"Authorization": `Bearer ${jwt}`}:null)
      },
    })
    
    return response.json()
  }



export {fetchPost, fetchPut, fetchGet}