
import { DocumentData, QuerySnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { subscribeToNewMessages } from '../api/firebaseHelpers'
import { getRoomsList, iRoom } from '../api/getRoomsList'
import { markRoomAsRead } from '../api/markRoomAsRead'
import { ichatMsgFromFirestore } from './useCurrentRoom'
import { useLoggedUser } from './useLoggedUser'


interface iuseRooms {
    rooms:iRoom[],
    index:{[key:string]:number}
    load: () => Promise<void>,
    markUnread: (roomId:string) => Promise<void>,
    markAsRead: (roomId:string) => Promise<void>,
    findById: (roomId:string) => undefined | iRoom,
    updateById: (roomId:string,data:Partial<iRoom>) => void,
    pushToTop:(roomId:string) => void
}

const getRoomLabel = (room:iRoom) => `${room.participant.name} ${room.participant.surname} ${room.participant.role_id}`

//todo: dodac pushToTop(roomId) - to bedzie wyciagalo room i dodawalo go na gorze + aktualizowalo index
const useRooms = create<iuseRooms>(
   (set,get) => ({
        rooms: [],
        index:{},
        load: async () => {
            const rooms = await getRoomsList()
            const index:{[key:string]:number} = {}
            rooms.forEach( (room,i) => index[room.roomId] = i)
            set({rooms,index})
        },
        markUnread: async (roomId:string) => {
            get().updateById(roomId,{unread:true})
        },
        markAsRead: async (roomId:string) => {
            const {updateById,findById} = get()
            const found = findById(roomId)
            //console.log('should mark as read? ',found)
            if(!found || found.unread == false)return;
            updateById(roomId,{unread:false,readAt:(new Date()).toJSON()})
            markRoomAsRead(roomId)
        },
        findById: (roomId:string) => get().index[roomId] !=undefined ? get().rooms[get().index[roomId]] : undefined, //get().rooms.find(room => room.roomId == roomId),
        updateById: (roomId:string,data:Partial<iRoom>) => {
            set(state => ({rooms:state.rooms.map(room => room.roomId == roomId ? Object.assign({},room,data): room)}))
        },
        pushToTop: (roomId:string) => {
            const {index,rooms} = get()
            const found = index[roomId]
            if(found === undefined)return;
            
            const newrooms = [rooms[found],...rooms.slice(0,found),...rooms.slice(found+1)]
            const newindex :{[key:string]:number} = {}
            newrooms.forEach( (room,i) => newindex[room.roomId] = i)
            set({rooms:newrooms,index:newindex})
        }
    })
)

let unsubscribeNewMessages = () => {}

useLoggedUser.subscribe(state => state.logged, (logged,prevlogged) => {
    if(logged === prevlogged) return;
    if(logged){
        unsubscribeNewMessages = subscribeToNewMessages(50, snapshot => {
            const {load} = useRooms.getState()
            if(!syncRoomsWithSnapshot(snapshot)){
                console.log('not every room was synced, loading rooms')
                load()
            }
        })
    } else {
        unsubscribeNewMessages()
    }
})

const syncRoomsWithSnapshot =  (snapshot:QuerySnapshot<DocumentData>) => {
    let allRoomsSynced = true;
    const {findById,markUnread} = useRooms.getState()
    const {uid,account_id} = useLoggedUser.getState()
    snapshot.forEach(doc => {
        if(!allRoomsSynced)return;
        const {date, content:{type:content_type,data:{roomId,message,accountId:fromAccountId}}} = doc.data() as ichatMsgFromFirestore
        if(fromAccountId == uid || fromAccountId == account_id || content_type != 'chat-message')return;
        const room = findById(roomId)
        //console.log('got message from ',room?.participant.name,room?.participant.surname,message)

        if(room === undefined){
            allRoomsSynced = false;
            return;
        }
        if(room.readAt === null){
            markUnread(roomId)
            return;
        }
        if(new Date(room.readAt) < new Date(date.seconds * 1000)){
            markUnread(roomId)
            return;
        }

    })
    return allRoomsSynced;
}

export {useRooms,getRoomLabel}
