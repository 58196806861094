import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { useLoggedUser } from './store/useLoggedUser';
import {App} from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const userlogged = useLoggedUser.getState().logged

root.render(
  <React.StrictMode>
    <App></App>
  </React.StrictMode>
);

let serviceWorkerReady : Promise<ServiceWorkerRegistration> | null = null

if (false && navigator.serviceWorker) {
  navigator.serviceWorker.register("sw.js");

  navigator.serviceWorker.addEventListener("message", (event) => {
    // event is a MessageEvent object
    console.log(`The service worker sent me a message: ${event.data}`);
  });

  serviceWorkerReady = navigator.serviceWorker.ready
  
  useLoggedUser.subscribe(state => state.jwt, jwt => serviceWorkerReady?.then(
    (registration:any) => registration.active.postMessage({jwt})
  )
)

}



