import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { onNewToken, signInToFirebase } from '../api/firebaseHelpers'

interface iLoggedUser {
    login:string,
    uid:string,
    jwt:string,
    account_id:string,
    logged:boolean,
    phase: 'fresh' | 'progress' | 'logged' | 'error',
    logUserIn: (login:string, password:string) => Promise<boolean>
}

const useLoggedUser = create<iLoggedUser>()(subscribeWithSelector(
    set => ({
        login:'',
        uid:'',
        account_id:"d956dc8e-be21-4aeb-8481-4e88cfee8b79",
        jwt:'',
        logged:false,
        phase: 'fresh',
        logUserIn: async (login:string, password:string) => {
            set({phase:"progress",login})
            const res = await signInToFirebase(login, password)
            if(res.error){
                set({phase:"error",uid:"",jwt:"",logged:false})
                return false;
                console.log('login error')
            }
            set({phase:"logged",uid:res.uid,jwt:res.jwt,logged:true})
            //console.log('JWT',res.jwt)
            return true;
        }
    })
))

function refreshToken(token:string){
    useLoggedUser.setState({jwt:token})
}

onNewToken(refreshToken)



export {useLoggedUser}