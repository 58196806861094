import { initializeApp } from "firebase/app";
import { Auth, getAuth, onIdTokenChanged, signInWithCustomToken } from "firebase/auth";
import { collection, query,doc, getFirestore, onSnapshot, Firestore, QuerySnapshot, DocumentData, where, orderBy, limit } from "firebase/firestore";
import { firebaseConfig,firebaseEnv } from "../config";
import { useLoggedUser } from "../store/useLoggedUser";
import { fetchLoginToken } from "./api";

const fapp = initializeApp(firebaseConfig)
const fdb = getFirestore(fapp);
const fauth = getAuth();

interface isignInToFirebase {
    error:boolean,
    jwt?:string,
    uid?:string,
    custom_token?:string
}

const getTransportCollection = (uid:string) => `TRANSPORT_BUS/${firebaseEnv}/${uid}/DATA/MESSAGES/`

async function signInToFirebase(login:string, password:string, auth:Auth = fauth, db:Firestore = fdb):Promise<isignInToFirebase>{
  let token = await fetchLoginToken(login,password)
  //console.log('signIn token ',token)

  if(token === '') return {error:true}


  try {
    const userCredential = await signInWithCustomToken(auth,token)
    //console.log(userCredential)
    const uid = userCredential.user.uid
    //console.log('loggedIn:',uid)//, await userCredential.user.getIdToken())

    return {
        error:false,
        uid,
        custom_token:token,
        jwt:await userCredential.user.getIdToken()
    }

  } catch (err) {
    console.log('signIn err ',err)
    return {error:true}
  }
  return {error:true}

}


/*     
.orderBy('date', 'desc')
.limit(queryLimit) 

.collection(userId)
    .doc('DATA')
    .collection('MESSAGES')
    .where('content.data.roomId', '==', roomId)
    .where('content.type', '==', 'chat-message');

    .orderBy('date', 'desc')
    .limit(queryLimit)
    .onSnapshot({includeMetadataChanges: true}, callback, errorCallback);

    return firestore()
    .collection('TRANSPORT_BUS')
    .doc(env.TRANSPORT_BUS_MAIN_COLLECTION);

    firestore.Timestamp.now();

*/

function subscribeToTransportBus(callback:(snapshot:QuerySnapshot<DocumentData>)=>void, db:Firestore = fdb){
    const uid = useLoggedUser.getState().uid
    return onSnapshot(collection(db,getTransportCollection(uid)),callback)
}

function subscribeToNewMessages(queryLimit:number, callback:(snapshot:QuerySnapshot<DocumentData>)=>void, db:Firestore = fdb){
    const uid = useLoggedUser.getState().uid
    const q = query(
            collection(db,getTransportCollection(uid)),
            //where('content.data.roomId', '==', roomId),
            //where('content.data.accountId', '!=', uid),
            //where('content.type', '==', 'chat-message'),
            orderBy('date', 'desc'),
            limit(queryLimit)
        )

    return onSnapshot(q,callback)
}


function subscribeToRoom(roomId:string, queryLimit:number, callback:(snapshot:QuerySnapshot<DocumentData>)=>void, db:Firestore = fdb){
    const uid = useLoggedUser.getState().uid
    const q = query(
            collection(db,getTransportCollection(uid)),
            where('content.data.roomId', '==', roomId),
            where('content.type', '==', 'chat-message'),
            orderBy('date', 'desc'),
            limit(queryLimit)
        )

    return onSnapshot(q,callback)
}

function onNewToken(callback:(token:string)=>void){
    onIdTokenChanged(fauth,(user)=>{
        user?.getIdToken().then(t=>t!="" && callback(t))
    })
}


export {signInToFirebase,getTransportCollection,subscribeToTransportBus,subscribeToRoom,subscribeToNewMessages,onNewToken}