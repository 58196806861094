import { useLoggedUser } from "../store/useLoggedUser"
import { AccessDenied, UserNotLogged } from "./apiErrors"
import { fetchPost } from "./restHelpers"

async function sendMessage(roomId:string,msg:string){
    const endpoint = "/common/chat/message"
 
    const {logged,jwt,account_id} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()

    const data = {
        roomId,
        optimisticId:roomId,
        profileAccountId:account_id,
        "isImportant": false,
        text:msg
      }
    
    const result = await fetchPost(endpoint,data,jwt)
    console.log('send message ',result)
    return result;
}

export {sendMessage}