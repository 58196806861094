import { useLoggedUser } from "../store/useLoggedUser"
import { AccessDenied, UserNotLogged, ServerError } from "./apiErrors"
import { fetchPut } from "./restHelpers"


// /api/admin/doctor-management/contract-status


/*{
  "doctorId": "string"
}*/

async function putDoctorAccept(doctorId:string) : Promise<boolean>{
    const endpoint = "/admin/doctor-management/contract-status"

    const {logged,jwt} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()
    
    const result = await fetchPut(endpoint,{doctorId},jwt)

    if(result?.status == 403)throw new AccessDenied()
    if(result?.status == 400)return false;
    return true;
}

export {putDoctorAccept}