import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react"
import { getRegisteredDoctors, iRegisteredDoc } from "./api/getRegisteredDoctors";
import { putDoctorAccept } from "./api/putDoctorAccept";
import { AppHeader } from "./components/AppHeader"

function RegisteredDoctorsApp(){

    const [data, setData] = useState<iRegisteredDoc[]>([]);
    const [phase, setPhase] = useState<"success"|"error"|"fetching">("fetching")

    const handleAccept = async (doctorId:string) => {
        console.log('attempting to accept '+doctorId)

        try {
            if(await putDoctorAccept(doctorId)){
                return true
            } else {
                return false
            }
        } catch(err) {
            //alert('Error while accepting doctor')
            return false
        }
        
    }

    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                setPhase("fetching")
                setData(await getRegisteredDoctors())
                setPhase("success")
            } catch(e) {
                setPhase("error")
            }

        }
        fetchData()
    },[])

    return <div className="RegisteredDoctorsApp">
            <AppHeader/>
            <div className='AppContent'>
            <h2>Registered doctors</h2>
            {
                phase == "error" ? "Error loading data"
                : phase == "fetching" ? "Loading..."
                : <DoctorsTable data={data} handleAccept={handleAccept}></DoctorsTable>
            }
            </div>
            </div>
}

function DoctorsTable({data, handleAccept}:{data:iRegisteredDoc[],handleAccept:(doctorId:string)=>Promise<boolean>}){

    const columns = useMemo<MRT_ColumnDef<iRegisteredDoc>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                size: 80
            }
            ,{
                accessorKey: 'surname',
                header: 'Surname',
                size: 150
            }
            ,{
                accessorKey: 'email',
                header: 'Email',
                enableClickToCopy: true
            }
            ,{
                accessorKey: 'phone',
                header: 'Phone',
                enableClickToCopy: true,
                size: 80
            }
            ,{
                accessorKey: 'city',
                header: 'City',
                size: 100
            }
            ,{
                id: 'specializations',
                accessorFn: (row) => row.specializations.join(', '),
                header: 'Specializations',
                size: 150
            }
            ,{
                accessorKey: 'registerDate',
                header: 'Registered',
                size: 100
            }
            ,{
                accessorKey: 'contractDataFinished',
                Cell: ({ row }) => row.original.contractDataFinished ? '✓' : 'x',
                header: 'contract',
                size: 50,
                filterVariant: 'checkbox',
                
            }
            ,{
                accessorKey: 'contractStatusAccepted',
                header: 'accepted',
                size: 50,
                filterVariant: 'checkbox',
                Cell: ({ row }) => row.original.contractStatusAccepted 
                    ? '✓' 
                    : <AcceptButton handleAccept={()=>handleAccept(row.original.id)} />,
            }

        ],[])

    return <MaterialReactTable columns={columns} data={data} initialState={{
        sorting: [
          { id: 'registerDate', desc: true }, //sort by state in ascending order by default
        ],
      }} />;
}


function AcceptButton({handleAccept}:{handleAccept:()=>Promise<boolean>}){

    const [phase, setPhase] = useState<"accept" | "try again" | "in progress" | "done">("accept")

    const acceptAction = async (ev:any) => {
        if(phase == "in progress" || phase == "done") return;
        setPhase("in progress")
        const result = await handleAccept()
        if(!result){
            setPhase('try again')
            return;
        }
        setPhase('done')
    }

    return <div className="BigButton" onClick={acceptAction}>{phase}</div>

}


/* 

[
  {
    "specializations": [
      "kardiologia",
      "choroby wewnętrzne"
    ],
    "id": "5c99e95f-fe45-4299-9d79-387201f7393f",
    "name": "Michał",
    "surname": "Machowski",
    "email": "michal.machowski@gmail.com",
    "phone": "790743181",
    "city": null,
    "registerDate": "2022-03-09T13:22:20.502Z",
    "p1DataFinished": false,
    "contractDataFinished": false,
    "contractStatusAccepted": true
  },
  {
    "specializations": [],
    "id": "88c7075e-59d8-4ef3-9405-c6b0a43e0654",
    "name": "Anna",
    "surname": "Kos",
    "email": "kosanka55@gmail.com",
    "phone": "666870282",
    "city": null,
    "registerDate": "2022-02-28T13:33:23.299Z",
    "p1DataFinished": false,
    "contractDataFinished": false,
    "contractStatusAccepted": true
  },

*/

export {RegisteredDoctorsApp}