
import './App.css';
import React, { useEffect, useRef, useState } from 'react';

import {  login, password } from './config';
import { useLoggedUser } from './store/useLoggedUser';
import { getRoomLabel, useRooms } from './store/useRooms';
import { ichatMsg, useCurrentRoom } from './store/useCurrentRoom';
import { iRoom } from './api/getRoomsList';
import { AppHeader } from './components/AppHeader';


const  cnames = (obj:any) => ({ className : Object.entries(obj).flatMap( ([k,v]) => v ? [k] : [] ).join(' ') } )

const LoginForm = () => {
  const user = useLoggedUser()
  const loadRooms = useRooms(s=>s.load) //wywalic do parametru

  const loginRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)

  const handleLogin = () => {
    if(user.phase == "progress") return;
    const login = loginRef.current?.value ?? ""
    const password = passwordRef.current?.value ?? ""
    if(login === "" || password === "") return;
    user.logUserIn(login,password).then(result => loadRooms() )
  }

  return user.logged ? null : <div className='LoginForm'>
    email: <input ref={loginRef} name="login"></input> password: <input ref={passwordRef} name="password" type={password}></input>
    <a className='BigButton' onClick={handleLogin}>{
      user.phase === "error" ? "Error, try again"
      :user.phase === "progress" ? "Loading..."
      : "Let me in"
    }</a>
  </div>

}

function ChatApp() {

  const user = useLoggedUser()
  const textRef = useRef<HTMLTextAreaElement | null>(null)
  const rooms = useRooms()

  const enterRoom = useCurrentRoom(s=>s.enterRoom)
  const sendMessage = useCurrentRoom(s=>s.sendMessage)
  const roomLabel = useCurrentRoom(s=>s.roomLabel)
  const room = useCurrentRoom(s=>s.room)
  const currentRoomId = room?.roomId

  useEffect(()=>{

    if(!user.logged){
      user.logUserIn(login,password).then(
        //result => rooms.load() 
      )
    }

  },[])

  const changeRoom = (room:iRoom) => {
    console.log('ROOM',room )
    enterRoom(room,getRoomLabel(room),100)
    rooms.markAsRead(room.roomId)
  }

  const handleSendMsg = () => {
    console.log('click send msg')
    const msg = textRef.current?.value ?? ''
    if(msg === '' || room === null)return;
    sendMessage(msg)
    rooms.pushToTop(room.roomId)
    if(textRef.current!==null){
      textRef.current.value = ''
      textRef.current.focus()
    }
  }

  const renderUserElement = (room : iRoom) => 
    <div {...cnames({UserElement:true,Unread:room.unread,Selected:room.roomId == currentRoomId,NewUser:room.newuser})} key={room.roomId} onClick={()=>changeRoom(room)}>
      {room.participant.name + ' ' + room.participant.surname + ' ' + room.participant.role_id}
      <div className='description'>{room.unread ? 'U ' : ' '}{room.lastMessage.createdDate != null ? (new Date(room.lastMessage.createdDate)).toLocaleString():'null'}</div>
    </div>


  return !user.logged 
    ? <div className="App"><LoginForm></LoginForm></div>
    : <div className="App">
      <AppHeader/>
      <div className='AppContent'>
        <div className='RoomsListContainer'>
          {
            !rooms.rooms.length ? "loading.." : null
          }
          {
            rooms.rooms.filter(room => room.unread).map(renderUserElement)
          }
          {
            rooms.rooms.filter(room => !room.unread).map(renderUserElement)
          }

        </div>
        <div className='ChatContainer'>
          <div className='ChatHeader'><b>{roomLabel}</b> <a href={"mailto:"+room?.participant.email}>{room?.participant.email}</a></div>
          <ChatPanel></ChatPanel>
          <div className='ChatFooter'>
            <textarea ref={textRef}></textarea>
            <a className="BigButton" onClick={handleSendMsg}>Wyślij</a>
          </div>
        </div>
      </div>
      <div className='AppFooter'>
          D1 Customer Service Chat
      </div>
    </div>
  
}

function ChatPanel(){
  const sending = useCurrentRoom(s=>s.sending)
  const loading = useCurrentRoom(s=>s.loading)
  const messages = useCurrentRoom(s=>s.messages)
  const account_id = useLoggedUser(s=>s.account_id)


  const renderMsg = (msg : ichatMsg,i:number, arr:ichatMsg[]) => {

    const date = new Date(msg.date.seconds * 1000);
    const nextdate = i < arr.length -1 ? new Date(arr[i+1].date.seconds * 1000) : date;
    const datefiff = Math.floor(Math.abs(date.getTime() - nextdate.getTime())/1000)
    const datestr = `${date.toLocaleDateString()},${date.getHours()}:${("0"+date.getMinutes()).slice(-2)}`

    //<div className='description'>{date.toLocaleString()}</div>

    return <React.Fragment>
      <div key={msg.id} {...cnames({ChatBubble:true,fromMe:msg.accountId == account_id})} >
        {msg.text} {msg.thumbUri ? <InChatImage src={msg.thumbUri} /> : null}
      </div>
      {
        i == arr.length-1 || datefiff > 30*60 ? <div className='ChatBubbleDate' key={datestr}>{datestr}</div> : null
      }
    </React.Fragment> 
  }

  return <div className='ChatPanel'>
    {
      sending ? <div className='ChatBubble fromMe'>sending..</div> : null
    }
    {
      loading 
      ? "Loading.." 
      : messages.map(renderMsg)
    }
  </div>
}

function InChatImage({src}:{src:string}){
  const JWT = useLoggedUser(s=>s.jwt)
  const ref = useRef<HTMLImageElement | null>(null)

  useEffect(()=>{
    const ImageRequest = new Request(
      src, {
        headers: {Authorization: "Bearer "+JWT},
        mode: "cors",
        credentials: "include",
      }
    )
    fetch(ImageRequest)
      .then(resp=>resp.blob())
      .then(blob=>ref.current !== null && (ref.current.src = URL.createObjectURL(blob)) )
  },[])

  return <img ref={ref} />

}

export default ChatApp;
