import { useLoggedUser } from "../store/useLoggedUser"
import { AccessDenied, UserNotLogged } from "./apiErrors"
import { fetchPost } from "./restHelpers"

async function markRoomAsRead(roomId:string){
    const endpoint = "/common/chat/mark-room-as-read"
 
    const {logged,jwt,account_id} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()

    const data = {
        roomId,
        profileAccountId:account_id,
        "markAsRead": true,
      }
    
    const result = await fetchPost(endpoint,data,jwt)
    console.log('mark-room-as-read ',result)
    return result;
}

export {markRoomAsRead}