import { create } from 'zustand'
import { subscribeToRoom } from '../api/firebaseHelpers'
import { iRoom } from '../api/getRoomsList'
import { sendMessage } from '../api/sendMessage'

interface iuseCurrentRoom {
    room:iRoom | null,
    roomLabel:string,
    messages:ichatMsg[],
    loading:boolean,
    sending:boolean,
    unsubscribe:()=>void,
    enterRoom:(room:iRoom,roomLabel:string,messagesLimit:number)=>void,
    sendMessage:(txt:string)=>void
}

//przeniesc sendMessage do rooms? to by od razu aktualizowalo pozycje pokoju na liscie
const useCurrentRoom = create<iuseCurrentRoom>(
    (set,get) => ({
         room:null,
         roomLabel:"",
         messages:[],
         loading:false,
         sending:false,
         unsubscribe:()=>{},
         enterRoom:(room:iRoom,roomLabel:string,messagesLimit:number)=>{
            get().unsubscribe()
            set({loading:true,room,roomLabel,messages:[]})
            const unsubscribe = subscribeToRoom(room.roomId,100,snapshot => {
                console.log('snapshot in room ',room.roomId,' triggered')
                const newmessages:ichatMsg[] = []
                snapshot.forEach(doc => newmessages.push(convertChatMsgFromFirestore(doc.id,doc.data() as ichatMsgFromFirestore)))
                set({loading:false,messages:newmessages})
            })
            set({unsubscribe})
         },
         sendMessage:(txt:string)=>{
            const roomId = get().room?.roomId
            if(!roomId)return;
            set({sending:true})
            sendMessage(roomId,txt).then(res => set({sending:false}))
         }
     })
 )
 
function convertChatMsgFromFirestore(id:string,{content:{data:{accountId,message}},date}:ichatMsgFromFirestore):ichatMsg {
    //console.log(id,message)
    return {
        id,
        accountId,
        type: message.type,
        fileExtension: message.body.fileExtension,
        fileName:message.body.fileName,
        alt:message.body.alt,
        thumbUri:message.body.thumbUri,
        uri:message.body.uri,
        text:message.body.text,
        date
    }
}


interface ichatMsg {
    id:string,
    accountId: string,
    type: string,
    fileExtension?:string,
    fileName?:string,
    alt?:string,
    thumbUri?:string,
    uri?:string,
    text?:string
    date:{seconds:number,nanoseconds:number}
}

interface ichatMsgFromFirestore {
    content: {
        data: {
            roomId:string,
            accountId: string,
            isDeleted: boolean,
            message: {
                type:string, //image, text
                body: {
                    fileExtension?:string,
                    fileName?:string,
                    alt?:string,
                    thumbUri?:string,
                    uri?:string,
                    text?:string
                }
            }

        },
        type: string //chat-message
    },
    date:{seconds:number,nanoseconds:number} //timestamp
}

 export {useCurrentRoom}
 export type {ichatMsgFromFirestore, ichatMsg}