import { useLoggedUser } from "../store/useLoggedUser"
import { AccessDenied, UserNotLogged, ServerError } from "./apiErrors"
import { fetchGet } from "./restHelpers"

type iRegisteredDoc = {
    specializations : string[],
    id:string,
    name:string,
    surname:string,
    email:string | null,
    phone:string | null,
    city: string | null,
    registerDate: string,
    p1DataFinished: boolean,
    contractDataFinished: boolean,
    contractStatusAccepted: boolean
}


async function getRegisteredDoctors() : Promise<iRegisteredDoc[]>{
    const endpoint = "/admin/doctor-management/registered-doctors"

    const {logged,jwt,account_id} = useLoggedUser.getState()
    if(!logged)throw new UserNotLogged()
    
    const result = await fetchGet(endpoint,undefined,jwt)

    if(result?.status == 403)throw new AccessDenied()
    if(!result?.length) throw new ServerError()

    return result as iRegisteredDoc[]

}

export {getRegisteredDoctors}
export type {iRegisteredDoc}