import { useRef } from "react"
import { useLoggedUser } from "../store/useLoggedUser"

const LoginForm = ({onLogin}:{onLogin?:()=>void}) => {
    const user = useLoggedUser()
  
    const loginRef = useRef<HTMLInputElement | null>(null)
    const passwordRef = useRef<HTMLInputElement | null>(null)
  
    const handleLogin = () => {
      if(user.phase == "progress") return;
      const login = loginRef.current?.value ?? ""
      const password = passwordRef.current?.value ?? ""
      if(login === "" || password === "") return;
      user.logUserIn(login,password).then(result => onLogin && onLogin() )
    }
  
    return user.logged ? null : <div className='LoginForm'>
      email: <input ref={loginRef} name="login"></input> password: <input ref={passwordRef} name="password" type="password"></input>
      <a className='BigButton' onClick={handleLogin}>{
        user.phase === "error" ? "Error, try again"
        :user.phase === "progress" ? "Loading..."
        : "Let me in"
      }</a>
    </div>
  
  }
  
  export {LoginForm}